/*! Generated by RiseDM */

@font-face {
    font-family: 'metropolis';
    src: url('Metropolis-Bold.woff2') format('woff2'),
         url('Metropolis-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'metropolis';
    src: url('Metropolis-Light.woff2') format('woff2'),
         url('Metropolis-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}

@font-face {
    font-family: 'metropolis';
    src: url('Metropolis-Medium.woff2') format('woff2'),
         url('Metropolis-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}